.center {
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}
.flex{
  display: flex;
}

:root {
  --main-bg-color: rgba(33, 41, 49, 1);
  --main-half-transparent-bg-color: rgba(33, 41, 49, 0.5);
  --neitral-bg-color: rgb(43, 51, 59);
  --footer-color: rgba(0,0,0,1);
  --main-text-color: rgba(247, 254, 253, 1);
  --main-white-color: rgba(255, 255, 255, 1);
  --main-blue-color:rgba(105, 179, 255, 1);
  --main-text-shadow-color: rgba(105, 179, 255, 0.53);
  --red-text-shadow-color: rgba(255, 105, 105, 0.53);
  --grey-disabled-color: rgba(247, 254, 253, 0.2);
  --angle: 90deg;
}

.text-glowing {
  text-shadow: 0 0 15px var(--main-text-shadow-color);
}

.as-grid {
  background-size: 2.5rem 2.5rem;
  background-image: radial-gradient(circle, rgba(247, 254, 253, 0.2) 1px, rgba(0, 0, 0, 0) 1px);
}

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@keyframes borderRotate {
  100% {
    --angle: 450deg;
  }
}

