
.col {
  box-sizing: border-box;
  flex: 1 1 0;
  &.reverse {
    flex-direction: column-reverse;
  }
  &.flex-col{
    display: flex;
    flex-direction: column;
  }

  @for $i from 0 through 9 {
    &.span-#{$i} {
      flex: $i 1 0;
    }
  }
}
