.hr-glow-line {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.6);
  position: relative;
  margin: 20px 0;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    filter: blur(2px);
  }
}
