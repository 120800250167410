.page-404 {
  .background {
    background-image: url('../../assets/404.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 80vh;

    .message {
      position: absolute;
      left: 25%;
      top: 15vw;
      backdrop-filter: blur(0.2rem);
      border: 1px solid rgba(33, 41, 49, 0.2);
      max-width: 50%;
      height: max-content;
      padding: 2rem;
      text-align: center;
      border-radius: 0.5rem;

      h1 {
        font-size: 2.2rem;
        padding-top: 0;
        margin-top: 0;
      }

      p {
        font-size: 1.5rem;
      }

      button {
        font-size: 1rem;
        border-radius: 0.3rem;
        padding: 0.3rem 0.5rem;
        background-color: transparent;
        color: var(--main-text-color);
        cursor: pointer;
      }
    }
  }
}
