@import "./utils/fonts";
@import "./utils/helpers";
@import "./utils/mixins";
// components
@import "./components/col";
@import "./components/row";
@import "./components/header";
@import "./components/logo";
@import "./components/icon";
@import "./components/cssIcon";
@import "./components/dropdown";
@import "./components/submitInput";
@import "./components/hr";
@import "./components/container";
@import "./components/footer";
@import "./components/loader";
@import "./components/particles";
@import "./components/pagination";
@import "./components/modal";
@import "./components/multiLangInput";
// pages
@import "./pages/index";
@import "./pages/page404";
@import "./pages/news";
@import "./pages/projects";
@import "./pages/aboutUs";
@import "./pages/contacts";
@import "./pages/admin";

body {
  margin: 0;
  font-family: 'Viaoda Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  overflow-x: hidden;
  &.no-scroll{
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  transition: box-shadow 0.2s, text-shadow 0.2s;
}

a {
  color: var(--main-text-color);
  text-decoration: none;
  font-family: "Cinzel Decorative", 'FoglihtenNo06', serif;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform .2s;
}

hr {
  border: none;
  height: 3px;
  margin: 1rem 0;
  background-image: linear-gradient(to right, rgba(33, 41, 49, 0), rgba(247, 254, 253, 0.5), rgba(33, 41, 49, 0));
  border-radius: 50%;
  position: relative;
}

.page {
  z-index: 1;
  position: relative;
}
