@font-face {
  font-family: 'Cinzel Decorative';
  src: url('./fonts/CinzelDecorative-Black.woff2') format('woff2'),
  url('./fonts/CinzelDecorative-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel Decorative';
  src: url('./fonts/CinzelDecorative-Bold.woff2') format('woff2'),
  url('./fonts/CinzelDecorative-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cinzel Decorative';
  src: url('./fonts/CinzelDecorative-Regular.woff2') format('woff2'),
  url('./fonts/CinzelDecorative-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Viaoda Libre';
  src: url('./fonts/ViaodaLibre-Regular.woff2') format('woff2'),
  url('./fonts/ViaodaLibre-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FoglihtenNo06';
  src: url('./fonts/FoglihtenNo06.woff2') format('woff2'),
  url('./fonts/FoglihtenNo06.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}





