.logo {
  display: inline;
  border: 0;
  background-color: transparent;
  padding: 0;
  font-family: 'Viaoda Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: var(--main-text-color);
  cursor: pointer;

  font-size: 4.5rem;
  line-height: 1;
  letter-spacing: -0.05em;
  text-align: left;
  text-shadow: 0 2rem 1rem rgba(33, 41, 49, 1);

  &.wrap {
    span {
      display: block;

      &.second-word {
        margin-left: 1rem;
        margin-top: -1.6rem;

        .first-letter {
          display: inline-block;
          transform: translateY(1.2rem);
        }
      }
    }
  }
}
