.news {
  background-color: var(--neitral-bg-color);
  margin: -2rem 0;
  padding: 2rem;
  min-height: 84.8vh;
  display: flex;

  @media screen and (max-width: 512px) {
    padding: 2rem 1rem;
  }

  .background-position {
    z-index: 1;
    position: relative;
  }

  .loading-wrapper, .error-wrapper {
    z-index: 2;
    backdrop-filter: blur(0.2rem);
    border: 1px solid rgba(247, 254, 253, 0.2);
    border-radius: 0.5rem;
    padding: 2rem;

    h2 {
      font-size: 2rem;
      text-shadow: 0 0 1rem var(--red-text-shadow-color);
    }

    p {
      font-size: 1.5rem;
      text-shadow: 0 0 1rem var(--red-text-shadow-color);
    }
  }

  .list {
    margin-top: 1rem;
    padding: 1rem;
    max-width: 20%;
    z-index: 2;

    &.mobile {
      max-width: 100%;
    }

    .pagination {
      .items-list {
        width: 100%;
        margin: 1rem 0;
        backdrop-filter: blur(0.2rem);
        border: 1px solid rgba(247, 254, 253, 0.2);
        border-radius: 0.5rem;
      }

      &.horizontal {
        align-items: normal;

        .items-list {
          margin: 0 1rem;

          .news-item {
            border-bottom: 0;
            border-right: 1px solid rgba(247, 254, 253, 0.2);

            @media screen and (max-width: 512px) {
              max-width: 30vw;
            }

            &:last-child {
              border-right: 0;
            }

            .date {
              position: static;
              display: block;
            }
          }
        }

        button {
          i {
            transform: scaleY(2.5) rotate(270deg);
          }

          &:last-child {
            i {
              transform: scaleY(2.5) rotate(90deg);
            }
          }
        }
      }

      button {
        backdrop-filter: blur(0.2rem);
        border: 1px solid rgba(247, 254, 253, 0.2);
        border-radius: 0.5rem;
        cursor: pointer;

        &:disabled i {
          background-color: var(--grey-disabled-color);
          cursor: not-allowed;
        }

        i {
          transform: scaleX(2.5);
        }

        &:last-child {
          i {
            transform: scaleX(2.5) rotate(180deg);
          }
        }
      }
    }

    .news-item {
      width: 100%;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(247, 254, 253, 0.2);
      box-sizing: border-box;
      position: relative;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }

      h1 {
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        margin: 0.8rem 0;
        width: 100%;
      }

      .date {
        font-size: 0.8rem;
        text-align: right;
        position: absolute;
        bottom: 0.2rem;
        right: 0.2rem;
      }

      .text {
        display: none;
      }

      &.active {
        color: var(--main-text-color);
        background-color: var(--main-text-shadow-color);
      }
    }
  }

  .content {
    height: 100%;
    z-index: 2;
    position: relative;
    text-align: justify;
    width: 100%;


    .active-element {
      padding: 1rem;
      margin: 2rem 1rem;
      backdrop-filter: blur(0.2rem);
      border: 1px solid rgba(247, 254, 253, 0.2);
      border-radius: 0.5rem;
      height: auto;

      .news-item {
        position: relative;

        h1.title {
          font-size: 2rem;
        }

        .date {
          position: absolute;
          top: -1.2rem;
          right: 0;
        }

        .text {
          font-size: 1rem;
        }
      }
    }
  }
}
