.about-us {
  background-color: var(--neitral-bg-color);
  margin: -2rem 0;
  padding: 2rem;
  min-height: 77.3vh;
  display: flex;
  font-size: 1.3rem;
  text-align: justify;
  background-image: url('../../assets/grid6.png');
  @media screen and (max-width: 512px) {
    padding: 2rem 1rem;
  }

  .wrapper {
    backdrop-filter: blur(0.4rem);
    border: 1px solid rgba(247, 254, 253, 0.2);
    border-radius: 0.5rem;
    padding: 1rem 2rem 2rem;

    img {
      object-fit: cover;
      width: 40%;
      float: left;
      padding: 1.5rem 1.5rem 0 0;
      border-radius: 0.5rem;
    }

    .link-wrapper {
      text-align: right;

      a {
        text-decoration: underline;
      }
    }
  }
}
