.projects {
  background-color: var(--neitral-bg-color);
  margin: -2rem 0;
  padding: 2rem;
  min-height: 77.3vh;
  display: flex;
  background-image: url('../../assets/grid3.png');

  @media screen and (max-width: 512px) {
    padding: 2rem 1rem;
  }

  .project-item {
    backdrop-filter: blur(0.4rem);
    border: 1px solid rgba(247, 254, 253, 0.2);
    border-radius: 0.5rem;
    padding: 1rem 2rem 2rem;
    margin-bottom: 2rem;

    .content {
      .image-wrapper {
        max-width: 40%;

        img {
          object-fit: cover;
          width: 100%;
          border-radius: 0.4rem;
        }
      }

      .description-block-wrapper {
        padding-left: 2rem;
        justify-content: space-between;
        align-content: space-between;
        display: flex;
        flex-direction: column;

        .buttons {
          justify-content: flex-end;
          align-items: flex-end;
          align-content: flex-end;

          button {
            white-space: nowrap;
            padding: 0.3rem 1rem;
            font-size: 1rem;
            border: 1px solid var(--main-bg-color);
            background-color: var(--main-text-color);
            color: var(--main-bg-color);
            cursor: pointer;
            border-radius: 0.1rem;
            @include media-min('lg') {
              font-size: 1.5rem;
              padding: 1rem;
            }

            &:hover {
              box-shadow: 0 0 1rem var(--main-text-shadow-color);
            }
          }
        }
      }
    }
  }
}

.modal {
  @media screen and (max-width: 512px) {
    .header {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 512px) {
    .content {
      overflow-y: scroll;
      overflow-x: hidden;

      & > .col {
        width: 100%;
      }
    }
  }

  .roller {
    height: 100%;
    flex-wrap: nowrap;
    padding-bottom: 0.5rem;
    @media screen and (max-width: 512px) {
      flex-direction: column;
      width: 100%;
      & > .col.main-wrapper, & > .col.description-wrapper, & > .col.images-wrapper {
        max-width: 100%;
        width: 100%;
        height: fit-content;
        margin-bottom: 1rem;
        min-height: fit-content;
        margin-left: 0;
      }
      & > .col.description-wrapper {
        height: fit-content;
        display: contents;

        & > .row {
          height: fit-content;

          & > .col.description {
            height: fit-content;
          }
        }
      }
    }

    .main-wrapper {
      min-width: 60vw;
      width: 60vw;
      max-width: 60vw;
      height: 100%;
      color: var(--main-bg-color);
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      .main-image-video {
        height: 70%;
        width: 100%;

        & > .col {
          width: 100%;
          height: 100%;

          video, img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            border-radius: 0.3rem;
            border: 1px solid rgba(33, 41, 49, 0.2);
          }
        }
      }

      .dates {
        color: var(--main-bg-color);
        font-size: 1rem;
      }

      .description-wrapper {
        width: 60vw;
      }

      .images {
        width: fit-content;

        & > .row {
          width: 60vw;
        }
      }

      .navigate-buttons {
        justify-content: flex-end;
        align-items: flex-end;
        align-content: flex-end;
        display: flex;

        button {
          padding: 1rem;
          font-size: 1.5rem;
          border: 1px solid var(--main-bg-color);
          background-color: var(--main-text-color);
          color: var(--main-bg-color);
          cursor: pointer;
          border-radius: 0.1rem;

          &:hover {
            box-shadow: 0 0 1rem var(--main-text-shadow-color);
          }
        }
      }
    }

    .description-wrapper {
      min-width: 40vw;
      width: 40vw;
      max-width: 40vw;
      height: 100%;
      color: var(--main-bg-color);
      margin-left: 1rem;
      border: 1px solid rgba(33, 41, 49, 0.2);
      border-radius: 0.3rem;
      padding: 1rem;
      overflow-y: auto;

      p {
        margin-top: 0;
      }

      .links a {
        color: var(--main-blue-color);
        display: block;
        padding: 0.3rem 0;

        &:before {
          content: '';
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 0.25rem;
          background-color: var(--main-bg-color);
          margin: -0.8rem 0.5rem 0 0;
        }
      }
    }

    .images-wrapper {
      margin-left: 1rem;
      height: 100%;
      display: flex;
      border: 1px solid rgba(33, 41, 49, 0.2);
      border-radius: 0.3rem;
      padding: 1rem;
      flex-direction: column;

      & > .row {
        height: 50%;
        flex-wrap: nowrap;
        margin-top: 1rem;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;

        &:first-child {
          margin-top: 0;
        }

        & > .col {
          margin-left: 1rem;
          height: 100%;
          width: fit-content;
          flex: none;

          &:first-child {
            margin-left: 0;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 1px solid rgba(33, 41, 49, 0.2);
            cursor: pointer;
          }
        }
      }
    }
  }

  &.image-wrapper-modal {
    width: fit-content;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 0.3rem;
    }
  }
}

