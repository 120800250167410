.admin {
  background-color: var(--neitral-bg-color);
  margin: -2rem 0;
  padding: 2rem;
  display: flex;
  font-size: 1.3rem;
  text-align: justify;
  min-height: 84.8vh;
  background-image: url('../../assets/grid6.png');

  .login-wrapper {
    margin: 2rem auto;
    backdrop-filter: blur(0.4rem);
    border: 1px solid rgba(247, 254, 253, 0.2);
    border-radius: 0.5rem;
    padding: 1rem;
    min-width: 15rem;

    label {
      display: block;
      font-size: 1rem;
    }

    input {
      font-size: 1rem;
      background-color: transparent;
      border: 1px solid rgba(247, 254, 253, 0.2);
      width: 100%;
      color: var(--main-text-color);
    }

    button {
      margin: 1rem 0 0;
      width: 100%;
      font-size: 1.2rem;
    }
  }

  .tab-wrapper {
    margin: 2rem auto;
    backdrop-filter: blur(0.4rem);
    border: 1px solid rgba(247, 254, 253, 0.2);
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;

    .tabs {
      .tab-key-wrapper {
        .tab-key {
          color: var(--main-text-color);
          width: 100%;
          border: 1px solid rgba(247, 254, 253, 0.2);
          background-color: transparent;
          font-size: 1.3rem;
          cursor: pointer;
        }

        &.active {
          .tab-key {
            background-color: var(--main-text-color);
            color: var(--main-bg-color);
          }
        }
      }
    }

    .content {
      margin-top: 1rem;
      border: 1px solid rgba(247, 254, 253, 0.2);

      .content-wrapper {
        height: fit-content;
        width: 100%;
        padding: 1rem;

        .new-item-wrapper {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          button {
            color: var(--main-text-color);
            padding: 0.2rem 1rem;
            border: 1px solid rgba(247, 254, 253, 0.2);
            background-color: transparent;
            font-size: 1.3rem;
            cursor: pointer;
          }
        }

        .items-wrapper {
          margin-top: 1rem;

          .row {
            .col {
              padding: 0.5rem;
              border-bottom: 1px solid rgba(247, 254, 253, 0.2);
              border-right: 1px solid rgba(247, 254, 253, 0.2);

              &:first-child {
                border-left: 1px solid rgba(247, 254, 253, 0.2);
              }

              &.head {
                border-top: 1px solid rgba(247, 254, 253, 0.2);
                background-color: var(--main-text-color);
                color: var(--main-bg-color);
                font-weight: bold;
              }
            }
          }

          font-size: 1rem;

          .date-wrapper {
            max-width: 9rem;
          }

          .action-wrapper {
            width: 4rem;
            max-width: 4rem;

            button {
              width: 1rem;
              height: 1rem;
              border: 0;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.modal.item-edit {
  &.news-element {
    .multi-lang-input > .col {
      height: 100%;
    }
  }

  .content {
    overflow-y: auto;

    .lang-list-of-images {
      color: var(--main-bg-color);
      font-size: 1rem;
      margin: 0 !important;

      .lang-key {
        text-transform: capitalize;
        font-size: 1rem;
      }

      .image-content-wrapper {
        .image-action {
          max-width: fit-content;

          button {
            margin-right: 1rem;
            width: 1.5rem;
            height: 1.5rem;
            border: none;
            background-color: var(--main-bg-color);
            color: var(--main-text-color);
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }
    }

    & > .col {
      display: flex;
      flex-direction: column;

      .row {
        margin-bottom: 1rem;

        &.content-wrapper {
          flex-grow: 2;
          padding-bottom: 1rem;

          textarea {
            height: 90%;
          }
        }

        &.buttons-wrapper {
          .col {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            &:last-child {
              align-items: flex-end;
              justify-content: flex-end;
            }

            button {
              border: 1px solid var(--main-bg-color);
              color: var(--main-bg-color);
              background-color: var(--main-text-color);
              width: 5rem;
              font-size: 1.2rem;
            }
          }
        }

        label {
          display: block;
          color: var(--main-bg-color);
        }

        input, textarea {
          display: block;
          width: 100%;
        }

        textarea {
          min-height: 6rem;
        }
      }
    }

  }
}
