.index {
  margin-bottom: 3rem;

  .background {
    background-image: url('../../assets/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 80vh;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      background: linear-gradient(0deg, var(--main-bg-color) 0%, rgba(0, 0, 0, 0) 49%);
      height: 1rem;
      width: 100%;
      bottom: 0;
    }

    .subscribe-wrapper {
      backdrop-filter: blur(0.2rem);
      border: 1px solid rgba(33, 41, 49, 0.2);
      border-radius: 0.5rem;
      position: absolute;
      width: fit-content;
      margin-right: 2rem;
      top: 10vh;
      left: 5vw;
      height: max-content;
      padding: 2rem;
      @media screen and (max-width: 512px) {
        left: 5vw;
      }


      .title {
        font-family: "Cinzel Decorative", 'FoglihtenNo06', serif;
        font-size: 3.5rem;
        text-shadow: 0 0 1rem rgba(33, 41, 49, 1);
        @media screen and (max-width: 512px) {
          font-size: 2rem;
        }
      }

      .content {
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
        text-shadow: 0 0 1rem rgba(33, 41, 49, 1);
        @media screen and (max-width: 512px) {
          font-size: 1.2rem;
        }
      }

      .submit-button {
        max-width: 25rem;
      }

      .subtext {
        font-size: 0.8rem;
        padding-top: 1rem;
        text-shadow: 0 0 1rem rgba(33, 41, 49, 1);
        max-width: 25rem;
      }
    }
  }

  .index-main-container {
    .items {
      border: 0.35rem solid;
      border-image: conic-gradient(from var(--angle), rgba(33, 41, 49, 0), rgba(247, 254, 253, 0.2) 0.1turn, rgba(247, 254, 253, 0.2) 0.15turn, rgba(33, 41, 49, 0) 0.25turn) 30;
      animation: borderRotate 4.5s linear infinite forwards;

      &:last-child {
        margin-top: -0.35rem;
      }

      .col {
        padding: 1rem;

        &.img-wrapper img {
          border-radius: 0.5rem;
          object-fit: contain;
          width: 100%;
        }

        &.text-wrapper {
          h2 {
            font-size: 1rem;
            @include media-min('md') {
              font-size: 2rem;
            }
          }

          p {
            font-size: 0.8rem;
            @include media-min('md') {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
