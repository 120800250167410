footer {
  background-color: var(--footer-color);
  height: 5rem;
  position:relative;
  color: var(--main-text-color);
  z-index: 2;

  &:before{
    content: "";
    width:100%;
    height: 1rem;
    position: absolute;
    top:-1rem;
    left:0;
    background: linear-gradient(180deg,rgba(0,0,0,0),var(--footer-color));
  }

  .copy {
    max-width: 18rem;
    font-family: "Cinzel Decorative", 'FoglihtenNo06', serif;
    text-align: right;
    white-space: nowrap;
  }
}
