.contacts {
  background-color: var(--neitral-bg-color);
  margin: -2rem 0;
  padding: 2rem;
  min-height: 77.3vh;
  display: flex;
  background-image: url('../../assets/grid2.png');
  @media screen and (max-width: 512px) {
    padding: 2rem 1rem;
  }

  .form-container {
    margin: 4rem auto;
    backdrop-filter: blur(0.2rem);
    border: 1px solid rgba(247, 254, 253, 0.2);
    border-radius: 0.5rem;
    padding: 2rem;

    h1 {
      border-bottom: 1px solid rgba(247, 254, 253, 0.2);
      margin: 0 -2rem 1rem;
      padding: 0 2rem 2rem;
    }

    .form-item-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0.3rem 0;

      label {
        font-size: 1.5rem;
      }

      input, textarea {
        font-size: 1.2rem;
        color: var(--main-text-color);
        background-color: transparent;
        border: 1px solid var(--main-text-color);
        border-radius: 0.5rem;
      }

      textarea {
        min-height: 4rem;
        outline: none;
        padding: 0.5rem;
      }

      &.data-processing {
        text-align: justify;

        h4 {
          text-align: center;
          margin: 0;
        }
      }
    }

    .button-wrapper {
      justify-content: flex-end;

      button[type="submit"] {
        text-align: center;
        background-color: transparent;
        border: 1px solid var(--main-text-color);
        font-size: 1.2rem;
        color: var(--main-text-color);
        border-radius: 0.2rem;
        padding: 0.5rem 1rem;
      }
    }
  }
}
