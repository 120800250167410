.row {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  &.reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 512px) {
  .row {
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
  }
}
