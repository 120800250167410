.change-language-item:first-letter {
  text-transform: uppercase;
}

header {
  position: relative;
  height: 8rem;
  z-index: 2;
  background-color: var(--main-bg-color);

  .burger {
    width: fit-content;
    height: calc(2rem + 9px);
    border: 0;
    background-color: transparent;
    position: absolute;
    left: 2rem;
    top: calc(50% - 1rem - 4.5px);
    padding: 0;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.5rem;
    cursor: pointer;

    div {
      width: 2rem;
      height: 3px;
      border: 0;
      background-color: var(--main-text-color);
      margin-top: 0.5rem;
    }
  }

  .change-language {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    border: 1px solid var(--main-text-color);
    border-radius: 0 0 0 0.5rem;
    border-top: 0;
    border-right: 0;
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  a {
    font-weight: bold;
    display: block;

    &:hover {
      transform: scaleY(120%);
    }

    &.is-icon {
      gap: 1rem;
      display: flex;
      max-width: max-content;
      margin-right: 2rem;

      &:hover {
        transform: none;

        &:hover > * {
          transform: scale(120%);
        }
      }
    }
  }

  &:after {
    content: " ";
    display: block;
    position: absolute;
    background: linear-gradient(180deg, var(--main-bg-color) 0%, rgba(0, 0, 0, 0) 49%);
    height: 1rem;
    width: 100%;
    bottom: -1rem;
  }

  .logo-wrapper {
    max-width: 11rem;
    min-width: 11rem;
    z-index: 3;
    position: relative;
    margin-top: 0.5rem;

    display: flex;
    flex-direction: column;
  }
}

.modal.burger-menu {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top:0.5rem;

  .content {
    & > .col > .row {
      a {
        color: var(--main-bg-color);
        margin-top: 2rem;

        i {
          background-color: var(--main-bg-color);
        }
      }


      &:last-child {
        align-items: center;
        align-content: center;
        justify-content: center;

        a {
          max-width: 2rem;
          margin-right: 1rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .logo {
      max-width: 11rem;
      min-width: 11rem;
      width: 11rem;
      color: var(--main-bg-color);
      display: block;
      overflow: visible;
      margin-top:-1rem;
    }
  }
}
