.dropdown {
  border: 0;
  background: transparent;
}

.dropdown-overflow {
  opacity: 0.01;
  z-index: 150;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dropdown-wrapper {
  z-index:151;
  position: absolute;
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  border:1px solid var(--main-bg-color);
  background-color: var(--main-text-color);
  color: var(--main-bg-color);
  cursor: pointer;
  border-radius: 3px;

  .dropdown-item {
    padding:0.5rem;
    width: max-content;
    height: max-content;
    border-bottom: 1px solid var(--main-bg-color);
    &:last-child {
      border-bottom: none;
    }
  }
}
