.modal-overflow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  z-index: 201;
}

.modal {
  z-index: 202;
  position: fixed;
  top: 10vh;
  height: 80vh;
  left: calc(100% - 95vw);
  width: 90vw;
  right: calc(100% - 95vw);
  background-color: var(--main-text-color);
  padding: 1rem;
  display: flex;
  flex-direction: column;

  .header {
    font-size: 2rem;
    padding: 1rem;
    border-bottom: 1px solid var(--main-bg-color);
    color: var(--main-bg-color);

    h3 {
      margin: 0;
    }
  }

  .content {
    padding-top: 1rem;
    font-size: 1.2rem;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;

    & > .col {
      height: 100%;
    }
  }

  .close {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    width: 2rem;
    height: 2rem;
    border: 1px solid var(--main-bg-color);
    color: var(--main-bg-color);
    background-color: transparent;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 0.1rem;
    cursor: pointer;

    &:before, &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 0.1rem;
      background-color: var(--main-bg-color);
      border-radius: 2px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
