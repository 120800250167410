.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.vertical {
    flex-direction: column;
  }

  .items-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &.vertical {
      flex-direction: column;
    }
  }

  button {
    background-color: transparent;
    width: 100%;
    color: var(--main-text-color);
    border: 0;
  }
}
