.multi-lang-input {
  .main-title-wrapper{
    color: var(--main-bg-color);

  }
  margin: 0.5rem !important;
  height: 100%;
  border:1px solid var(--main-half-transparent-bg-color);
  padding:0.5rem;

  .sub-content-item {
    color: var(--main-bg-color);
    font-size: 1rem;
    border:1px dashed var(--main-blue-color);
    padding:0.5rem;
    border-bottom: 0;
    margin:0.5rem 0 0 0;
    &:last-of-type{
      border-bottom:1px dashed var(--main-blue-color);
    }

    a {
      color: var(--main-bg-color);
      font-size: 1rem;
      text-transform:none;
      font-family: monospace;
    }
  }

  & > .col {
    height: fit-content;

    & > .row {
      height: 100%;
      margin: 0 !important;

      .locale-list-wrapper {
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 !important;

        .col {
          width: fit-content;
          max-width: fit-content;

          button {
            background-color: var(--main-bg-color);
            color: var(--main-text-color);
            border: 1px solid var(--grey-disabled-color);
            cursor: pointer;

            &.active {
              color: var(--main-blue-color);
            }
          }
        }
      }
    }
  }

}
